<template>
    <div class="auth-layout">
        <div class="progress">
                <div class="item item01">
                    <img src="@/assets/images/btn-auth-select.png" width="24px" height="24px" alt="">
                    <span>选择认证类型</span>
                </div>
                <div class="line"></div>
                <div class="item active">
                    <div class="num">2</div>
                    <span>填写认证信息</span>
                </div>
                <div class="line"></div>
                <div class="item">
                    <div class="num">3</div>
                    <span>审核认证信息</span>
                </div>
                <div class="line"></div>
                <div class="item">
                    <div class="num">4</div>
                    <span>认证结果</span>
                </div>
            </div>
        <div class="auth-form">   
            <div class="cont">
                <el-form :model="formInfo.form" :label-width="120" :rules="formInfo.rules" ref="form" size="large">
                    <el-form-item label="认证称号" prop="name">
                        <el-input v-model="formInfo.form.name" placeholder="请输入认证账号"/>
                        <div class="tips">认证称号官方格式：使用企业全称或简称（例如：XX官方账号），如称号与企业名称不一致，（例如使用企业旗下产品或应用软件等）需补充上传关联证明材料。</div>
                    </el-form-item>
                    <el-form-item label="运营者身份证姓名" prop="operatorl_name">
                        <el-input v-model="formInfo.form.operatorl_name" placeholder="请输入姓名"/>
                        <div class="tips">账号实际运营者需和运营授权函上一致，并在授权函上手写签字</div>
                    </el-form-item>
                    <el-form-item label="运营者手机号" prop="operatorl_name">
                        <el-select v-model="phoneRange" style="width:114px;">
                            <el-option label="中国大陆" :value="中国大陆" />
                        </el-select>
                        <el-input v-model="formInfo.form.operatorl_name" placeholder="请输入姓名" style="width:260px;"/>
                        <div class="tips">手机号用于接收验证码，建议使用有效手机号</div>
                    </el-form-item>
                    <el-form-item label="验证码" prop="operatorl_name">
                        <el-input v-model="formInfo.form.operatorl_name" placeholder="请输入验证码"><template #append>发送验证码</template></el-input>
                    </el-form-item>
                    <el-form-item label="联系邮箱" prop="operatorl_name">
                        <el-input v-model="formInfo.form.operatorl_name" placeholder="请输入内容"/>
                    </el-form-item>
                    <el-form-item label="事业单位证明/营业执照">
                        <el-upload :action="upload_url" :headers="headers" :limit="1" :on-success="handleImgSuccess" :show-file-list="false" style="height:120px;margin-right:8px;">
                            <div class="identCardUpload">
                                <el-image class="pic" style="width:120px;height:120px;" fit="contain" :src="formInfo.form.avatar" lazy>
                                    <template #error>
                                         <div class="info">
                                            <img src="/static/images/icon-add.png" alt="">
                                            <span>点击上传图片</span>
                                         </div>
                                    </template>
                                </el-image>
                            </div>
                        </el-upload>
                        <div class="tips">每张小于2M，支持JPG/PNG等格式，最多上传5张图片上传资料要求：请上传最新版三证合一高清彩色版企业营业执照，或加盖红色企业公章的复印件（非电子公章），请确保营业执照完整清晰，便于识别。查看示例</div>
                    </el-form-item>
                    <el-form-item label="统一社会信用代码" prop="operatorl_name">
                        <el-input v-model="formInfo.form.operatorl_name" placeholder="请输入内容"/>
                    </el-form-item>
                    <el-form-item label="企业全称" prop="enterprise_name">
                        <el-input v-model="formInfo.form.enterprise_name" placeholder="请输入内容"/>
                        <div class="tips">企业名称需与营业执照上的名称一致</div>
                    </el-form-item>
                    <el-form-item label="企业分类" prop="operatorl_name">
                        <el-select v-model="type1" style="width:180px;">
                            <el-option label="一级分类" value="一级分类"></el-option>
                        </el-select>
                        <el-select v-model="type1" style="width:180px;">
                            <el-option label="二级分类" value="二级分类"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="机构类型" prop="operatorl_name">
                        <el-select v-model="type" style="width:180px;">
                            <el-option label="互联网" value="互联网"></el-option>
                        </el-select>
                        <div class="tips">请选择与营业执照主营范围内的一致的行业，显示禁入的类型表示为官方认证不准入企业</div>
                    </el-form-item>
                    <el-form-item label="企业规模" prop="enterprise_scale">
                        <el-select v-model="enterprise_scale" placeholder="请选择企业规模">
                            <el-option label="互联网" value="互联网"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="注册资金" prop="operatorl_name">
                        <el-input v-model="formInfo.form.operatorl_name" placeholder="请输入注册资金"/>
                    </el-form-item>
                    <el-form-item label="所在地址" prop="operatorl_name">
                        <el-input v-model="formInfo.form.operatorl_name" placeholder="请输入所在地址"/>
                    </el-form-item>
                    <el-form-item label="官网地址" prop="operatorl_name">
                        <el-input v-model="formInfo.form.operatorl_name" placeholder="官网地址"/>
                        <div class="tips">如有企业官网，请填写正确的官网地址</div>
                    </el-form-item>
                    <el-form-item label="补充文件">
                        <el-upload :action="upload_url" :headers="headers" :limit="1" :on-success="handleImgSuccess" :show-file-list="false" style="height:120px;margin-right:8px;">
                            <div class="identCardUpload">
                                <el-image class="pic" style="width:120px;height:120px;" fit="contain" :src="formInfo.form.avatar" lazy>
                                    <template #error>
                                         <div class="info">
                                            <img src="/static/images/icon-add.png" alt="">
                                            <span>点击上传图片</span>
                                         </div>
                                    </template>
                                </el-image>
                            </div>
                        </el-upload>
                        <div class="tips">每张小于2M，支持JPG/PNG等格式，最多上传5张图片上传资料要求：请 上传最新版三证合一高清彩色版企业营业执照，或加盖红色企业公章的复印 件（非电子公章），请确保营业执照完整清晰，便于识别。查看示例</div>
                    </el-form-item>
                    <div class="btn-submit" @click="onSubmit">提交申请</div>
                    <div class="agreement">
                        <el-checkbox v-model="checked1" size="large" />
                        <span>我已同意《ikeyhub咋办官方认证服务协议》</span>
                    </div>
                </el-form>
            </div>
        </div>
    </div>
</template>
<script>
import user from "@/api/user";
export default{
    name:'enterpriseAuth',
    components: {},
    data() {
    return {
        phoneRange:'中国大陆',
        formInfo:{
                form:{
                    enterprise_name:''
                },
                rules:{
                    enterprise_name: [{required: true, message: '请输入企业名称', trigger: 'blur'}]
                }
            }
    }
  },
  created() {
    
  },
  methods: {
    // 提交认证信息
    async onSubmit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
            user.enterpriseAuth(this.formInfo.form).then(res => {
            console.log('res',res)
          });
        } else {
          return false
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.auth-layout{padding:60px 0;background:#fff;font-family: PingFang SC;}
.progress{display: flex;align-items: center;justify-content: center; margin-bottom:60px;
    .item{font-size: 16px;color: rgba(0, 0, 0, 0.4);display:flex;align-items:center;
        .num{display: inline-block;width: 24px;height: 24px;border: 1px solid rgba(0, 0, 0, 0.4);border-radius: 50%;text-align: center;}
        span{margin-left:16px;}
        &.item01{color: rgba(0, 0, 0, 0.9);}
        &.active{color: #000000;
            .num{background:#000;color: rgba(255, 255, 255, 0.9);}
        }
    }
    .line{width:80px;height:2px;margin:0 16px;background: #DCDCDC;
        &.finish{background: #000000;}
    }
}
.auth-form{width:520px;margin:0 auto; 
   .title{font-size: 20px;font-weight: bold;line-height: 26px;margin-bottom:40px;text-align: center;color: #333333;}
   .el-form-item--large{margin-bottom:45px;}
   .tips{font-size: 12px;line-height: 20px;color: rgba(0, 0, 0, 0.4);}
}
.btn-submit{width:152px;height: 48px;line-height:48px;margin:0 auto;background: #000000;border-radius: 6px;font-size: 18px;color: #C1F421;font-weight: 500;text-align: center;cursor:pointer;}
</style>